import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyCmrB3OwtvryJC6_O_HGZumPlzYJ290CH4',
  authDomain: 'popile-firebase-staging.firebaseapp.com',
  databaseURL: 'https://popile-firebase-staging-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'popile-firebase-staging',
  storageBucket: 'popile-firebase-staging.appspot.com',
  messagingSenderId: '607403871652',
  appId: '1:607403871652:web:844587bb2ca9593f754531',
  measurementId: 'G-PJBRZV1E99',
}

const app = initializeApp(firebaseConfig)
const functions = getFunctions(app)

if (location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { functions, app }
