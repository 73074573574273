<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <img :src="image" width="100" alt="Vuestic Admin" class="app-navbar" />
      </div>
    </template>
    <template #center>
      <div v-if="reportStore.docData?.createdAt?._seconds" class="mr-2 flex flex-col items-center justify-center mx-2">
        <h4 class="font-bold">Instagram Hesap Analizi</h4>
        <h5 class="text-xs mb-2">
          Oluşturma tarihi:
          {{
            new Date(reportStore.docData?.createdAt?._seconds * 1000).toLocaleDateString('tr-TR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}
        </h5>
      </div>
    </template>
    <template #right>
      <div>
        <VaButton icon="share" color="darkpurple" class="mr-4" @click="share"></VaButton>
      </div>
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
// import { storeToRefs } from 'pinia'
// import { useGlobalStore } from '../../stores/global-store'
import image from '/popile-logo.png'
// import AppNavbarActions from './components/AppNavbarActions.vue'
// import VuesticLogo from '../VuesticLogo.vue'
import { useInstagramReportStore } from '../../stores/report-store'

const reportStore = useInstagramReportStore()

defineProps({
  isMobile: { type: Boolean, default: false },
})

//share button functionality to share the current url
const share = () => {
  if (navigator.share) {
    navigator
      .share({
        title: 'Instagram Hesap Analizi',
        text: 'Instagram Hesap Analizi',
        url: window.location.href,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error))
  } else {
    console.log('Web share not supported')
    //fallback for browsers that do not support web share api
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        alert('Link kopyalandı')
      })
      .catch((error) => console.log('Error copying', error))
  }
}

// const GlobalStore = useGlobalStore()

// const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
