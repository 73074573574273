interface InstagramReportResponse {
  report: Report
  doc: any
}
// stores/instagramReportStore.ts
import { defineStore } from 'pinia'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase/init'
import { Report } from '../types/report' // Import the interface

export const useInstagramReportStore = defineStore('instagramReport', {
  state: () => ({
    reportData: null as Report | null, // Use the interface here
    loading: false,
    error: null as any,
    docData: null as any,
  }),
  actions: {
    async fetchInstagramReport(id: string) {
      this.loading = true
      this.error = null
      const fetchInstagramReport = httpsCallable(functions, 'getInstagramReport')
      console.log('FETCHING REPORT')
      try {
        const result = await fetchInstagramReport({ id })
        // Ensure result.data is of type InstagramReport before assigning
        if (result.data && typeof result.data === 'object') {
          this.reportData = (result.data as InstagramReportResponse).report
          this.docData = (result.data as InstagramReportResponse).doc
          console.log('Pinia store reportData:', result.data)
        } else {
          throw new Error('Invalid report data')
        }
      } catch (error) {
        console.error('Error fetching report:', error)
        this.error = error
        throw error
        // the error is sent in firebase cloud functions format - catch it and console log the status code
      } finally {
        this.loading = false
      }
    },
  },
  getters: {
    demographyAgeChartData: (state) => state.reportData?.user.demography_by_age,
    demographyBasicData: (state) => state.reportData?.user.demography,
  },
})
