// A function export that adds this script to the vue3 application : <script async defer src="https://tools.luckyorange.com/core/lo.js?site-id=dfb6807d"></script>
// The script is added to the application in the main.js file

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install: (app) => {
    ;(function (w, d, s, i) {
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
      j.async = true
      j.defer = true
      j.src = 'https://tools.luckyorange.com/core/lo.js?site-id=' + i
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dfb6807d')
  },
}
